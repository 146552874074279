.countdown {
  .time-left {
    width: max-content;
    margin: 1rem auto 1rem auto;
    background-color: rgba(102,102,102,0.8);
    font-family: Helvetica, serif;
    color: #ffffff;
    padding: 5px 20px 16px 20px;
    line-height: 1;
    border-radius: 15px;
  }

  .unit-time-left {
    font-family: "Helvetica", serif;
    line-height: 1;
    font-weight: 200;
    color: #ffffff;
    font-size: 6.5em;
  }

  .seconds {
    color: #d4602c;
  }

  .time-name {
    font-size: 1.4em;
  }

  .starting-soon {
    font-family: "Agency FB Bold", serif;
    -webkit-text-stroke: 2px #000;
    color: #ffffff;
    margin-top: 37px;
    margin-bottom: 46px;
    font-size: 4em;
  }
}
