html,
body,
#root {
  font-size: 16px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

.wrapper {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  background-image: url("/assets/img/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.bg-primary {
  background-color: #002146 !important;
}

.navbar {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.navbar-brand {
  font-family: "Marcellus SC", serif;
  font-size: 1.7rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: #f5f5f5;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.title {
  font-family: "Marcellus SC", serif;
  color: #394d77;
}

.card {
  margin-bottom: 1rem;
}

.roll {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  font: 300 30px/1 "Open Sans Condensed", sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  animation: 30s credits linear infinite;

  &__name {
    margin-bottom: 50px;
    font-size: 20px;
  }
}

@keyframes credits {
  0% {
    top: 100%;
  }
  100% {
    top: -200%;
  }
}
