#reading {
  position: relative;
  width: fit-content;
  min-width: 50%;
  max-width: 90%;
  padding: 10px 20px;
  font-size: 20px;
  position: relative;
  top: 82%;

  color: #fff;
  background: #002146;
  border-bottom: 3px solid #d6a419;

  .text {
    color: #fff;
    font-family: "Marcellus SC", serif;
  }
  &-title {
    font-size: 1.5rem;
  }
  &-reading {
    font-size: 2rem;
  }
  &-verse {
    font-family: "Helvetica", serif;
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    box-sizing: content-box;

    background: #002146;
    border-bottom: 3px solid #d6a419;
    border-right: 20px solid #d6a419;

    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
  }
}
