#intro {
  width: 100%;
  height: 100%;

  #background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 100, 0.3);*/
    /*background-color: rgba(0, 0, 127, 0.25);*/
    z-index: 2;
    .overlay-off & {
      z-index: 0;
    }
  }

  #background-video {
    position: fixed;
    left: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: fill;
    z-index: 1;
    opacity: 0.7;
  }

  .content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-align: center;
  }

  .heading {
    line-height: 1;
    font-size: 13.5rem;
    font-family: "Agency FB Bold", serif;
    font-stretch: expanded;
    color: #ffffff;
    -webkit-text-stroke: 5px #000;
  }

  .end {
    font-family: "Agency FB Bold", serif;
    -webkit-text-stroke: 3px #000;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 60px;
    font-size: 4rem;
  }

  .today {
    font-family: "Agency FB Bold", serif;
    color: #ffffff;
    -webkit-text-stroke: 3px #000;
    font-size: 5rem;
    line-height: 1;
    padding-top: 10px;
  }
}
