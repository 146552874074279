@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik&family=Marcellus+SC&display=swap");

@font-face {
  font-family: "Agency FB Bold";
  src: url("/assets/fonts/AGENCYB.TTF");
}

@font-face {
  font-family: "Agency FB Regular";
  src: url("/assets/fonts/AGENCYR.TTF");
}

/* Theme colors
#002146, #394D77, #E5F0FF, #D6A419, #FFEECA, #877555
*/
